import QRCode from "react-qr-code";

const NotMobile = (props: any) => {
	return (
		<>
			<p className="text-5xl font-medium">
				Supercharge is only available on mobile
			</p>
			<p className="text-xxl mt-6 font-medium">
				Scan QR Code on your device to continue
			</p>
			<div className="mt-16">
				<QRCode
					size={200}
					style={{
						height: "200px",
						width: "200px",
					}}
					value={window.location.href}
					// viewBox={`0 0 256 256`}
				/>
			</div>
		</>
	);
};

export default NotMobile;
