import { lazy } from "react";
import { Navigate } from "react-router-dom";

const MainLayout = lazy(() => import("../pages/Layout/Main"));
const ClubLayout = lazy(() => import("../pages/Layout/Club"));
const UserLayout = lazy(() => import("../pages/Layout/User"));

const Home = lazy(() => import("../pages/Home/Home"));
const WelcomeScreen = lazy(() => import("../pages/Home/WelcomeScreen"));

const Auth = lazy(() => import("../pages/Auth/Auth"));
const NTU = lazy(() => import("../pages/Auth/NTU"));

const Welcome = lazy(() => import("../pages/Auth/Welcome"));
const VerifyEmail = lazy(() => import("../pages/Auth/VerifyEmail"));
const OTPVerify = lazy(() => import("../pages/Auth/OTPVerify"));

const Clubs = lazy(() => import("../pages/Club/Clubs"));
const CreatorPod = lazy(() => import("../pages/Club/CreatorPod"));
const ClubDetail = lazy(() => import("../pages/Club/Detail"));
const IDCenter = lazy(() => import("../pages/MemberClub/IDCenter"));
const ClubInvite = lazy(() => import("../pages/MemberClub/Invite"));
const ClubAceptInvite = lazy(() => import("../pages/MemberClub/AcceptInvite"));
const Achievement = lazy(() => import("../pages/MemberClub/Achievement"));
const ClubInfo = lazy(() => import("../pages/Club/Info"));
const ClubLevel = lazy(() => import("../pages/MemberClub/Level"));
const ClubRules = lazy(() => import("../pages/Creator/ClubRules"));
const ClubVotes = lazy(() => import("../pages/Creator/ClubVotes"));
const ClubMembers = lazy(() => import("../pages/Creator/ClubMember"));
const ClubMemberTier = lazy(() => import("../pages/Creator/ClubMemberTier"));
const ClubManager = lazy(() => import("../pages/Creator/ClubManager"));
const ClubCheckin = lazy(() => import("../pages/Club/CheckIn"));
const ClubTask = lazy(() => import("../pages/MemberClub/Tasks"));

const ClubMessages = lazy(() => import("../pages/ClubMessages/Messages"));
const ClubNewChat = lazy(() => import("../pages/ClubMessages/NewChat"));
const ClubChat = lazy(() => import("../pages/ClubMessages/Chat"));
const ClubAssistantToolUI = lazy(() => import("../pages/Chat/ClubOpenAI"));

const ClubVoteDetail = lazy(() => import("../pages/Votes/Vote"));

const Subscriptions = lazy(() => import("../pages/Subscription/Subscriptions"));
const SubscriptionDetail = lazy(() => import("../pages/Subscription/Detail"));
const ClubSubscriptionDetail = lazy(
	() => import("../pages/Subscription/ClubSubscriptionDetail")
);

const PostDetail = lazy(() => import("../pages/Home/PostDetail"));

const UserSettings = lazy(() => import("../pages/Settings/Settings"));
const UserMessages = lazy(() => import("../pages/Messages/Messages"));
const UserProfile = lazy(() => import("../pages/Profile/Profile"));
const PublicProfile = lazy(() => import("../pages/Profile/PublicProfile"));
const FanPass = lazy(() => import("../pages/Profile/FanPass"));

const EditProfile = lazy(() => import("../pages/Profile/EditProfile"));
const ChangePassword = lazy(() => import("../pages/Settings/ChangePassword"));
const UserNewChat = lazy(() => import("../pages/Messages/NewChat"));
const Chat = lazy(() => import("../pages/Messages/Chat"));
const UserInventory = lazy(() => import("../pages/Profile/Inventory"));
const UserWallet = lazy(() => import("../pages/Wallet/Wallet"));
const BuyCredit = lazy(() => import("../pages/Wallet/BuyCredit"));
const RedeemHistory = lazy(() => import("../pages/Profile/RedeemHistory"));
const TransactionHistory = lazy(
	() => import("../pages/Wallet/TransactionHistory")
);
const FanToken = lazy(() => import("../pages/Wallet/FanToken"));

const Missions = lazy(() => import("../pages/Mission/Missions"));
const MissionDetail = lazy(() => import("../pages/Mission/MissionDetail"));
const PlayMission = lazy(() => import("../pages/Mission/PlayMission"));
const Discover = lazy(() => import("../pages/Discover/Discover"));
const Campaign = lazy(() => import("../pages/Campaign/Campaign"));

const Market = lazy(() => import("../pages/Market/Market"));
const Gacha = lazy(() => import("../pages/Market/Gacha"));

const ItemQR = lazy(() => import("../pages/Sample/ItemQR"));

const Station = lazy(() => import("../pages/Station/Station"));

const CreatorClub = lazy(() => import("../pages/Creator/Creator"));
const CreateClub = lazy(() => import("../pages/Creator/CreateClub"));
const CreateClubChatAI = lazy(() => import("../pages/Creator/ChatAI"));
const ClubSettings = lazy(() => import("../pages/Creator/ClubSettings"));
const ClubHome = lazy(() => import("../pages/Creator/ClubHome"));
const ClubMission = lazy(() => import("../pages/Creator/ClubMission"));
const ClubPost = lazy(() => import("../pages/Creator/ClubPost"));
const ClubStation = lazy(() => import("../pages/Creator/ClubStation"));
const ClubCreateModule = lazy(() => import("../pages/Creator/Module"));
const ClubMissionRequirement = lazy(
	() => import("../pages/CreatorMission/Requirement")
);
const ClubSubscription = lazy(
	() => import("../pages/Creator/ClubSubscriptions")
);
const ClubGacha = lazy(() => import("../pages/Creator/ClubGacha"));
const ClubStore = lazy(() => import("../pages/Creator/ClubStore"));
const ClubAchievement = lazy(() => import("../pages/Creator/ClubAchievement"));
const ClubItems = lazy(() => import("../pages/Creator/ClubItems"));
const UpgradeLicense = lazy(() => import("../pages/Creator/UpgradeLicense"));
const ClubOverview = lazy(() => import("../pages/Creator/ClubOverview"));
const ClubOpenAIProfile = lazy(() => import("../pages/Creator/OpenAIProfile"));

const DiscordHandler = lazy(() => import("../pages/Mission/DiscordHandler"));
const TiktokHandler = lazy(() => import("../pages/Mission/TiktokHandler"));

const Notifications = lazy(
	() => import("../pages/Notifications/Notifications")
);
const Badge = lazy(() => import("../pages/Badge/Badge"));

const NoPermission = lazy(() => import("../pages/Error/NoPermission"));
function getRoutes() {
	const superchargeRoutes = [
		{
			path: "/auth",
			element: <Auth />,
		},
		{
			path: "/permission-error",
			element: <NoPermission />,
		},
		{
			path: "/welcome",
			element: <Welcome />,
		},
		{
			path: "/verify-email",
			element: <VerifyEmail />,
		},
		{
			path: "/NTU",
			element: <NTU />,
		},
		{
			path: "/item-qr",
			element: <ItemQR />,
		},
		{
			path: "/discord",
			element: <DiscordHandler />,
		},
		{
			path: "/tiktok",
			element: <TiktokHandler />,
		},
		{
			path: "/otp-verify",
			element: <OTPVerify />,
		},
		{
			path: "/mission/:id",
			element: <MissionDetail />,
		},
		{
			path: "/campaign/:id",
			element: <Campaign />,
		},
		{
			path: "/",
			element: <MainLayout />,
			children: [
				{
					path: "/discover",
					element: <Discover />,
				},
				{
					path: "/",
					element: <Navigate to={"/home"} />,
				},
				{
					path: "/home",
					element: <Home />,
				},
				{
					path: "/missions",
					element: <Missions />,
				},
				{
					path: "/clubs",
					element: <Clubs />,
				},
				{
					path: "/messages",
					element: <UserMessages />,
				},
				{
					path: "/notifications",
					element: <Notifications />,
				},
				{
					path: "/station/:id",
					element: <Station />,
				},
			],
		},
		{
			path: "/club",
			element: <ClubLayout />,
			children: [
				{
					path: ":id",
					element: <ClubDetail />,
				},
				{
					path: "post-detail/:id",
					element: <PostDetail />,
				},
				{
					path: ":id/id-center",
					element: <IDCenter />,
				},
				{
					path: ":id/invite",
					element: <ClubInvite />,
				},
				{
					path: "invite/:code",
					element: <ClubAceptInvite />,
				},
				{
					path: "mission-detail/:id",
					element: <MissionDetail />,
				},
				{
					path: "play-mission/:id",
					element: <PlayMission />,
				},
				{
					path: ":id/market",
					element: <Market />,
				},
				{
					path: "gacha/:id",
					element: <Gacha />,
				},
				{
					path: ":id/achievement",
					element: <Achievement />,
				},
				{
					path: "station/:id",
					element: <Station />,
				},
				{
					path: ":id/settings",
					element: <ClubSettings />,
				},
				{
					path: ":id/home",
					element: <ClubHome />,
				},
				{
					path: ":id/missions",
					element: <ClubMission />,
				},
				{
					path: ":id/stations",
					element: <ClubStation />,
				},
				{
					path: ":id/posts",
					element: <ClubPost />,
				},
				{
					path: ":id/module",
					element: <ClubCreateModule />,
				},
				{
					path: ":id/requirement",
					element: <ClubMissionRequirement />,
				},
				{
					path: ":id/info",
					element: <ClubInfo />,
				},
				{
					path: ":id/subscriptions",
					element: <Subscriptions />,
				},
				{
					path: "subscription-detail/:id",
					element: <SubscriptionDetail />,
				},
				{
					path: "club-subscription/:id",
					element: <ClubSubscriptionDetail />,
				},
				{
					path: ":id/manage-subscriptions",
					element: <ClubSubscription />,
				},
				{
					path: ":id/levels",
					element: <ClubLevel />,
				},
				{
					path: ":id/manage-rules",
					element: <ClubRules />,
				},
				{
					path: ":id/manage-votes",
					element: <ClubVotes />,
				},
				{
					path: "vote-detail/:id",
					element: <ClubVoteDetail />,
				},
				{
					path: ":id/manage-members",
					element: <ClubMembers />,
				},
				{
					path: ":id/manage-member-tier",
					element: <ClubMemberTier />,
				},
				{
					path: ":id/manage",
					element: <ClubManager />,
				},
				{
					path: ":id/manage-gacha",
					element: <ClubGacha />,
				},
				{
					path: ":id/manage-store",
					element: <ClubStore />,
				},
				{
					path: ":id/manage-achievement",
					element: <ClubAchievement />,
				},
				{
					path: ":id/manage-items",
					element: <ClubItems />,
				},
				{
					path: ":id/vault",
					element: <ClubItems />,
				},
				{
					path: ":id/messages",
					element: <ClubMessages />,
				},
				{
					path: ":id/new-chat",
					element: <ClubNewChat />,
				},
				{
					path: ":id/chat/:user_name",
					element: <ClubAssistantToolUI />,
				},
				{
					path: ":id/upgrade-license",
					element: <UpgradeLicense />,
				},
				{
					path: ":id/overview",
					element: <ClubOverview />,
				},
				{
					path: ":id/checkin",
					element: <ClubCheckin />,
				},
				{
					path: "levels",
					element: <ClubLevel />,
				},
				{
					path: "tasks",
					element: <ClubTask />,
				},
				{
					path: "badges",
					element: <Badge />,
				},
				{
					path: ":id/openai-profile",
					element: <ClubOpenAIProfile />,
				},
			],
		},
		{
			path: "/user",
			element: <UserLayout />,
			children: [
				{
					path: ":id",
					element: <PublicProfile />,
				},
				{
					path: "welcome",
					element: <WelcomeScreen />,
				},
				{
					path: "settings",
					element: <UserSettings />,
				},
				{
					path: "creatorpod",
					element: <CreatorPod />,
				},
				{
					path: "profile",
					element: <UserProfile />,
				},
				{
					path: "editprofile",
					element: <EditProfile />,
				},
				{
					path: "inventory",
					element: <UserInventory />,
				},
				{
					path: "wallet",
					element: <UserWallet />,
				},
				{
					path: "fan-token",
					element: <FanToken />,
				},
				{
					path: "transaction-history",
					element: <TransactionHistory />,
				},
				{
					path: "buy-credit",
					element: <BuyCredit />,
				},
				{
					path: "change-password",
					element: <ChangePassword />,
				},
				{
					path: "redeem-history",
					element: <RedeemHistory />,
				},
				{
					path: "creator-club",
					element: <CreatorClub />,
				},
				{
					path: "create-club",
					element: <CreateClub />,
				},
				{
					path: "create-club-ai",
					element: <CreateClubChatAI />,
				},
				{
					path: "new-chat",
					element: <UserNewChat />,
				},
				{
					path: "chat/:id",
					element: <Chat />,
				},
				{
					path: "fanpass",
					element: <FanPass />,
				},
			],
		},
	];

	return superchargeRoutes;
}

export default {
	getRoutes: getRoutes,
};
